import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../context/Store/StoreContext";
import { useContext, useEffect, useState } from "react";
import { appSettingsUtil } from "../../utils/env.util";
import { useTheme } from "styled-components";
import { Loader } from "../../components/Loader/Loader";
import { jsMessageInterceptor } from "../../services/bridge.service";
import { MOBBY } from "./Mobby.styles";
import { TITLE } from "../../styles/base.styles";
import { listenEventUtil, removeEventUtil } from "../../utils/event.util";
import { EMobbyAppEvents } from "../../types/global.interface";

const Mobby = (): JSX.Element => {
  const navigate = useNavigate();
  const { setStore } = useContext(StoreContext);
  const [ pageTitle, setPageTitle ] = useState("");
  const theme = useTheme() as any;
  const [ loading, setLoading ] = useState(true);

  const setPageTitleHandler = (event: CustomEvent) => {
    setPageTitle(event.detail)
  }

  useEffect(() => {
    window.addEventListener("message", jsMessageInterceptor, false);
    listenEventUtil(EMobbyAppEvents.SetTitle, setPageTitleHandler)
    setLoading(false)

    return () => {
      window.removeEventListener("message", jsMessageInterceptor, false);
      removeEventUtil(EMobbyAppEvents.SetTitle, setPageTitleHandler);
    };

  }, [])

  if (loading) return <Loader color={ theme.companySecondary } text={ "Autenticando" } isFullSize/>

  return (
    <MOBBY>
      <div className="mobby__wrapper">
        <TITLE>{ pageTitle || "Mobby" }</TITLE>
        <iframe
          src={ appSettingsUtil.mobbyUrl }
          title="MobbyHub"
          id="mobby-hub-iframe">
        </iframe>
      </div>
    </MOBBY>
  )
}

export {
  Mobby
}