import styled from "styled-components";
import { IHeaderSC } from "./Header.interface";
import { CONTAINER } from "../../styles/base.styles";
import { mediaConfig } from "../../styles/themeConfig";

const HEADER = styled.header <IHeaderSC>`
  width: 100%;
  text-align: center;
  height: 60px;
  margin-bottom: 32px;

  ${ mediaConfig.desktop } {
    padding: 0 8px;
    height: 70px;
    margin-bottom: 70px;
  }

  > ${ CONTAINER } {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    ${ mediaConfig.tablet } {
      box-shadow: 0 0 10px ${ ({ theme }) => theme.lightgray };
      z-index: 10;
    }

    ${ mediaConfig.desktop } {
      align-items: flex-end;
      justify-content: flex-start;
    }

    > img {
      height: 16px;

      ${ mediaConfig.desktop } {
        height: 30px;
      }
    }

    > button {
      ${ mediaConfig.desktop } {
        display: none;
      }
    }

    > h1 {
      font-size: 1.4rem;
      font-weight: bold;
      color: ${ ({ theme }) => theme.darkgray };
      margin: 0;
      display: none;
    }
  }

  &.aside {
    ${ mediaConfig.desktop } {
      position: fixed;
      left: 0;
      top: 0;
      width: 120px;
      height: 100vh;
      background-color: ${ ({ theme }) => theme.lightgray2 };
      border-right: 1px solid ${ ({ theme }) => theme.lightgray };
      box-shadow: inset 0 0 10px ${ ({ theme }) => theme.lightgray };

      > ${ CONTAINER } {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding-top: 16px;
        padding-bottom: 16px;
        height: auto;

        > img {
          height: auto;
          width: 100%;
          margin-top: 16px;
        }

        > h1 {
          font-size: 1.4rem;
          font-weight: bold;
          color: ${ ({ theme }) => theme.darkgray };
          margin: 0;
          display: none;
        }
      }
    }
  }

`


const MENU = styled.aside`
  height: calc(100% - 52px);
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  transition: var(--transition-all);

  ${ mediaConfig.tablet } {
    background-color: ${ ({ theme }) => theme.light };
    z-index: 9;
    position: fixed;
    right: -100%;
    top: 52px;
    padding-top: 32px;

    &.active {
      right: 0;
    }
  }

  ${ mediaConfig.desktop } {
    width: 100%;
    height: 100%;
    margin-top: 48px;
    flex-direction: column;
    justify-content: flex-start;
  }

  .menu {
    &__item {
      width: 100%;
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      ${ mediaConfig.tablet } {
        flex-direction: row-reverse;
      }

      ${ mediaConfig.desktop } {
        justify-content: center;
        flex-direction: column;
      }

      &.active {
        > span {
          color: ${ ({ theme }) => theme.companySecondary };
        }
      }

      span {
        color: ${ ({ theme }) => theme.gray };
        font-size: 1.3rem;

        ${ mediaConfig.tablet } {
          font-weight: bold;
        }
      }

      button {
        margin-left: 8px;

        ${ mediaConfig.desktop } {
          margin-left: 0;
          margin-bottom: 6px;
        }
      }

      &--logout {
        margin-top: auto;
      }
    }
  }

`;

export {
  HEADER,
  MENU
}