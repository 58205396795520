import React, { useEffect, useState } from "react";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { Controller, useFormContext } from "react-hook-form";
import { validateFieldUtil } from "../../utils/form.util";
import { INPUT, RADIO, SELECT, SWITCHER } from "./Input.styles";
// @ts-ignore
import { NumberFormatValues, NumericFormat, PatternFormat } from "react-number-format";
// @ts-ignore
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import {
  EInputType,
  IDocumentProps,
  IErrorHandlerProps,
  IInputProps,
  ILabelHandlerProps,
  ILicensePlateProps,
  IRadioProps,
  ISelectOption,
  ISelectProps,
  ISwitchProps
} from "./Input.interface";
import { ArrowDownIcon } from "../Icon/Icon";
import { removeBlankSpaceUtil, removeSpecialCharactersUtil } from "../../utils/convert.util";

export const Input = (props: IInputProps) => {
  const { control } = useFormContext();
  const rules: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> = {
    validate: value => validateFieldUtil(props.name, value, props.noValidate, props.type),
    required: {
      value: props.type === EInputType.Switcher ? false : props.required || false,
      message: `${ props.label } é obrigatório`
    },
    min: {
      value: props.minValue ? props.minValue : "disabled",
      message: `O valor mínimo é ${ props.minValue }`
    },
    max: {
      value: props.maxValue ? props.maxValue : "disabled",
      message: `O valor máximo é ${ props.maxValue }`
    },
    // maxLength: {
    //   value: props.maxLength,
    //   message: `O tamanho máximo é ${ props.maxLength } caracteres`
    // },
    // minLength: {
    //   value: props.minLength,
    //   message: `O tamanho mínimo é ${ props.minLength } caracteres`
    // }
  }

  return (
    <INPUT>
      <Controller
        control={ control }
        name={ props.name }
        defaultValue={ props.type === EInputType.Checkbox ? false : "" }
        rules={ rules }
        render={ (field) => {
          return (
            <div className={ `input-group input-group--${ props.type }` }>
              <InputHandler { ...props } field={ field }/>
              <LabelHandler
                label={ props.label }
                name={ props.name }
                error={ field.fieldState.error }
                type={ props.type }
                readOnly={ props.readOnly }
                required={ props.required || false }/>
              <ErrorHandler error={ field.fieldState.error }/>
            </ div>
          )
        } }
      />
    </INPUT>
  )
}

const InputHandler = ({
  name,
  type = EInputType.Text,
  isUpperCase = false,
  onFocus,
  options,
  switchLabel,
  readOnly = false,
  selectFn,
  field,
  children,
  minDate,
  maxDate,
  minLength,
  maxLength,
  maxValue,
  minValue = 0,
  format,
  placeholder
}: IInputProps): JSX.Element => {
  const [ isFilled, setIsFilled ] = useState(false);
  let commonProps: any = {
    type,
    readOnly: readOnly,
    "aria-label": name,
    "data-testid": name,
    id: name,
    placeholder: placeholder,
    onBlur: (e: any) => {
      if (!field.field.value) setIsFilled(false)
      else setIsFilled(true);
    }
  }
  const { field: fieldUtils, fieldState } = field
  const inputClasses = `input-group__input input-group__input--${ type } ${ isUpperCase ? "input-group__input--uppercase" : "" } ${ fieldState.error ? "input-group__input--invalid" : "" }  ${ readOnly ? "input-group--readonly" : "" } ${ isFilled ? "input-group__input--filled" : "" }`;

  switch (type) {
    case EInputType.Text:
      commonProps = {
        ...commonProps,
        className: inputClasses,
        onFocus,
        id: fieldUtils.name
      }

      return (
        <input { ...fieldUtils } { ...commonProps } />
      )

    case EInputType.Number:
      commonProps = {
        ...commonProps,
        className: inputClasses,
        onFocus,
      }

      return (
        <input { ...fieldUtils } { ...commonProps } onChange={ e => {
          const value = e.target.value
          if (!value) return fieldUtils.onChange("");
          if (isNaN(Number(value))) return
          fieldUtils.onChange(Number(value))
        } }/>
      )

    case EInputType.Mask:
      commonProps = {
        ...commonProps,
        className: inputClasses,
        onFocus,
        format: format,
      }

      return (
        <PatternFormat { ...field.field } { ...commonProps } />
      )

    case EInputType.Password:
      commonProps = {
        ...commonProps,
        className: inputClasses,
        onFocus,
        id: fieldUtils.name
      }

      return (
        <input { ...field.field } { ...commonProps }/>
      )

    case EInputType.Document:
      commonProps = {
        ...commonProps,
        className: inputClasses,
      }

      return (
        <Document field={ fieldUtils } commonProps={ commonProps }/>
      )

    case EInputType.Email:
      commonProps = {
        ...commonProps,
        className: inputClasses,
        onFocus,
      }

      return (
        <input { ...field.field } { ...commonProps }/>
      )

    case EInputType.Select:
      commonProps = {
        ...commonProps,
        placeholder: placeholder,
        className: inputClasses,
      }

      return (
        <Select
          field={ fieldUtils }
          options={ options || [] }
          selectFn={ selectFn }
          commonProps={ commonProps }
        />
      )

    case EInputType.Switcher:
      commonProps = {
        ...commonProps,
        className: inputClasses,
        onFocus,
      }

      return (
        <Switcher commonProps={ commonProps } field={ fieldUtils } switchLabel={ switchLabel }/>
      )

    case EInputType.File:
      commonProps = {
        ...commonProps,
        className: inputClasses,
        onFocus,
      }

      return (
        <input { ...field.field } { ...commonProps }/>
      )

    case EInputType.Radio:
      commonProps = {
        ...commonProps,
        className: inputClasses,
        onFocus,
      }

      return (
        <Radio field={ field.field } commonProps={ commonProps } children={ children }/>
      )

    case EInputType.Checkbox:
      commonProps = {
        ...commonProps,
        onFocus,
      }

      return (
        <div className={ inputClasses }>
          <input { ...field.field } { ...commonProps }/>
          { children }
        </div>
      )

    case EInputType.Textarea:
      commonProps = {
        ...commonProps,
        className: inputClasses,
        onFocus,
        rows: 4
      }

      return (
        <textarea { ...field.field } { ...commonProps }></textarea>
      )

    case EInputType.Date:
      commonProps = {
        ...commonProps,
        className: inputClasses,
        onFocus,
        min: minDate ? minDate.toISOString().split('T')[0] : null,
        max: maxDate ? maxDate.toISOString().split('T')[0] : null,
      }

      return (
        <input { ...field.field } { ...commonProps } defaultValue={ null }/>
      )

    case EInputType.LicensePlate:
      commonProps = {
        ...commonProps,
        className: inputClasses,
      }

      return (
        <LicensePlate
          field={ fieldUtils }
          commonProps={ commonProps }/>
      )

    case EInputType.Currency:
      commonProps = {
        ...commonProps,
        className: inputClasses,
      }

      return (
        <NumericFormat
          { ...fieldUtils }
          { ...commonProps }
          decimalSeparator=","
          thousandSeparator="."
          prefix="R$"
          decimalScale={ 2 }
          isAllowed={ (values: NumberFormatValues) => {
            const { floatValue } = values;

            if (floatValue === undefined) return true;

            if (maxValue) {
              return floatValue <= maxValue;
            }

            return floatValue;
          } }
        />
      )

    default:
      return <React.Fragment></React.Fragment>

  }
}

const ErrorHandler = ({ error }: IErrorHandlerProps) => {

  if (!error) return null;

  return (
    <span
      key="error-0" data-testid={ `
  error` } className="input-group__error">{ error.message }</span>
  );
};

const LabelHandler = ({ label, name, required, type, error, readOnly }: ILabelHandlerProps) => {
  if (label) label = label.charAt(0).toUpperCase() + label.slice(1);

  if (!label) return null;

  if (type == EInputType.Checkbox) return null;

  return <label
    htmlFor={ name }
    className={ `
  input-group__label ${ error ? " input-group__label--error" : "" } ${ readOnly ? " input-group__label--read-only" : "" }` }>{ label }{ (required && type !== EInputType.Switcher) && "*" }</label>;
};

const Select = ({ field, options, selectFn, commonProps }: ISelectProps) => {
  const { setValue } = useFormContext();
  const [ fieldValue, setFieldValue ] = React.useState<ISelectOption>({ id: "", name: "" });
  const [ filteredOptions, setFilteredOptions ] = React.useState<Array<ISelectOption> | []>([]);
  const [ isActive, setIsActive ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!fieldValue.id) setValue(field.name, "")
    if (!commonProps?.readOnly && fieldValue.id !== "") field.onChange(fieldValue.id)
    if (selectFn) selectFn(fieldValue);
  }, [ fieldValue ])

  React.useEffect(() => {
    if (!field.value) setFieldValue({ id: "", name: "" })
  }, [ field.value ])

  const windowClickHandler = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (target.getAttribute("data-selector") !== "select") setIsActive(false);
  }

  React.useEffect(() => {
    window.addEventListener('click', windowClickHandler);

    return () => window.removeEventListener("click", windowClickHandler)

  }, [])


  return (
    <SELECT className="input-group__select">
      <input { ...field.field } { ...commonProps }/>
      <input
        type="text"
        alt="select"
        className={ commonProps?.className }
        style={ { textTransform: "uppercase" } }
        data-selector="select"
        value={ options?.filter((option: ISelectOption) => option.id === field.value)[0]?.name || fieldValue.name }
        onFocus={ () => setIsActive(true) }
        readOnly={ commonProps?.readOnly }
        // placeholder={ commonProps?.placeholder ? commonProps.placeholder : !options || !options.length ? "Lista Vazia" : commonProps?.placeholder }
        onChange={ e => {
          setFieldValue({ id: "", name: e.target.value });
          const newOptions: Array<ISelectOption> = options.filter((option: ISelectOption) => option.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
          setFilteredOptions([ ...newOptions ]);
        } }/>
      <ArrowDownIcon color={ "#D9D9D9" }/>
      {
        isActive
        && <ul data-selector="select">
          {
            filteredOptions.length
              ? filteredOptions.map(({ id, name }) => <li
                key={ id }
                onClick={ () => {
                  setFieldValue({ id, name })
                } }
                className={ id === fieldValue.id ? "active" : "" }>{ name }</li>)
              : options.map(({ id, name }) => <li
                key={ id }
                onClick={ () => {
                  setFieldValue({ id, name })
                } }
                className={ id === fieldValue.id ? "active" : "" }>{ name }</li>)
          }
        </ul>
      }
    </SELECT>
  )
}

const Switcher = ({ field, commonProps }: ISwitchProps) => {
  const { getValues, setValue } = useFormContext();
  const switchValue = getValues(field.name);

  React.useEffect(() => {
    if (!field.value) setValue(field.name, false);
  }, []);

  return (
    <SWITCHER
      active={ switchValue }
      htmlFor={ field.name }>
      <span>{ switchValue ? "Ativo" : "Inativo" }</span>
      <input
        { ...field }
        { ...commonProps }
        id={ field.name }
        type="checkbox"/>
      <div></div>
    </SWITCHER>
  );
};

const Radio = ({ field, commonProps, children }: IRadioProps) => {
  return (
    <RADIO>
      <input { ...field.field } { ...commonProps } style={ { display: "none" } }/>
      { children }
    </RADIO>
  )
}

const LicensePlate = ({ field, commonProps }: ILicensePlateProps) => {
  const { setValue: setFieldValue } = useFormContext();
  const [ value, setValue ] = useState("");

  const checkValue = (e: any) => {
    if (e.target.value.length <= 7) {
      if (e.nativeEvent.data) {
        if (value.length < 3) {
          if (/[a-zA-Z]+/.test(e.nativeEvent.data)) {
            setValue(e.target.value);
          }
        } else if (value.length === 4) {
          if (Number.isInteger(Number(e.nativeEvent.data)) || /[a-zA-Z]+/.test(e.nativeEvent.data)) {
            setValue(e.target.value);
          }
        } else if (Number.isInteger(Number(e.nativeEvent.data))) {
          setValue(e.target.value);
        }
      } else {
        if (e.target.value.length < value.length) setValue(e.target.value);
        else if (/[A-Za-z]{3}[0-9][0-9A-Za-z][0-9]{2}/.test(e.target.value)) setValue(e.target.value);
      }
    }
  };

  useEffect(() => {
    if (field.name) setFieldValue(field.name, (removeBlankSpaceUtil(value) || ""));
  }, [ value ]);

  return (
    <>
      <input { ...field.field } { ...commonProps } style={ { display: "none" } }/>
      <input
        className={ commonProps?.className }
        type="text"
        value={ value }
        onChange={ checkValue }/>
    </>
  );
};

const Document = ({ field, commonProps }: IDocumentProps) => {
  const { setValue: setFieldValue } = useFormContext();
  const [ value, setValue ] = useState("");

  useEffect(() => {
    if (field.name) setFieldValue(field.name, (removeSpecialCharactersUtil(value) || ""));
  }, [ value ]);

  useEffect(() => {
    if (!field.value) setValue("")
    else if (field.value !== value) setValue(field.value)
  }, [ field.value ])

  return (
    <>
      <input { ...field.field } { ...commonProps } type={ "text" } style={ { display: "none" } }/>
      <CpfCnpj
        value={ value }
        className={ commonProps?.className } onChange={ (e: any) => {
        setValue(e.target.value);
      } }/>
    </>
  );
};