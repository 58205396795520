import {
  cartaoAmericanExpressAsset,
  cartaoEloAsset,
  cartaoHipercardAsset,
  cartaoMastercardAsset,
  cartaoVisaAsset
} from "../assets";

const getCardBrandByName = (brand: string) => {
  switch (brand) {
    case "amex":
      return cartaoAmericanExpressAsset;
    case "elo":
      return cartaoEloAsset;
    case "hipercard":
      return cartaoHipercardAsset;
    case "master":
      return cartaoMastercardAsset;
    case "visa":
      return cartaoVisaAsset;
    default:
      return ""
  }
}

const getCardBrandByNumber = (cardNumber: string): string => {
  const regVisa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
  const regMaster = new RegExp("^5[1-5][0-9]{14}$");
  const regAmex = new RegExp("^3[47][0-9]{13}$");
  const regElo = new RegExp("^(40117[8-9]|431274|438935|451416|457393|45763[1-2]|506(699|7[0-6][0-9]|77[0-8])|509\\d{3}|504175|627780|636297|636368|65003[1-3]|6500(3[5-9]|4[0-9]|5[0-1])|6504(0[5-9]|[1-3][0-9])|650(4[8-9][0-9]|5[0-2][0-9]|53[0-8])|6505(4[1-9]|[5-8][0-9]|9[0-8])|6507(0[0-9]|1[0-8])|65072[0-7]|6509(0[1-9]|1[0-9]|20)|6516(5[2-9]|[6-7][0-9])|6550([0-1][0-9]|2[1-9]|[3-4][0-9]|5[0-8]))");
  const regHipercard = new RegExp("^(606282\\d{10}(\\d{3})?)|(3841(0|4|6)0\\d{13})$");

  if (regVisa[Symbol.match](cardNumber))
    return "visa";
  else if (regMaster[Symbol.match](cardNumber))
    return "master";
  else if (regAmex[Symbol.match](cardNumber))
    return "amex";
  else if (regElo[Symbol.match](cardNumber))
    return "elo";
  else if (regHipercard[Symbol.match](cardNumber))
    return "hipercard";
  else
    return "";
}

export {
  getCardBrandByName as getCardBrandByNameUtil,
  getCardBrandByNumber as getCardBrandByNumberUtil
}