import { REGISTER } from "./PaymentMethods.styles";
import { CONTAINER, ROW, SUBTITLE, TITLE } from "../../styles/base.styles";
import { InputProvider } from "../../components/Input/Input.context";
import { useForm } from "react-hook-form";
import { Input } from "../../components/Input/Input";
import { EInputType } from "../../components/Input/Input.interface";
import { Loader, ProgressBar } from "../../components/Loader/Loader";
import { Button } from "../../components/Button/Button";
import { useContext, useEffect, useState } from "react";
import { removeBlankSpaceUtil } from "../../utils/dump.util";
import {
  ICardRegisterStepProps,
  IPostCreditCard,
  IPostCreditCardAddress,
  IPostCreditCardContact
} from "./PaymentMethods.interface";
import {
  postCreditCardAddressService,
  postCreditCardContactService,
  postCreditCardService
} from "../../services/paymentMethods.service";
import { getCardBrandByNameUtil, getCardBrandByNumberUtil } from "../../utils/creditCard.util";
import { notifyUtil } from "../../utils/toast.util";
import { ENotificationTypes } from "../../types/global.interface";
import { useTheme } from "styled-components";
import { PaymentMethodsContext } from "../../context/PaymentMethods/PaymentMethodsContext";
import { stateListUtil } from "../../utils/form.util";
import { EPaymentMethodsActionTypes } from "../../context/PaymentMethods/PaymentMethodsContext.interface";
import { ERoutes } from "../../router/Router.interface";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [ loading, setLoading ] = useState(true);
  const [ progress, setProgress ] = useState<number>(0);
  const [ currentStep, setCurrentStep ] = useState<number>(1);
  const theme = useTheme() as any;
  const { paymentMethods } = useContext(PaymentMethodsContext);

  useEffect(() => {
    if (paymentMethods.registerCardData) {
      if (paymentMethods.registerCardData.step === 2) {
        setCurrentStep(2)
        setProgress(66);
      } else if (paymentMethods.registerCardData.step === 3) {
        setCurrentStep(3)
        setProgress(100);
      }
      setLoading(false)
    } else {
      setLoading(false)
    }
  }, []);

  useEffect(() => {
    if (currentStep === 1) {
      setProgress(33)
    } else if (currentStep === 2) {
      setProgress(66)
    } else if (currentStep === 3) {
      setProgress(100)
    }
  }, [ currentStep ]);


  if (loading) return (
    <REGISTER>
      <CONTAINER>
        <div className="register__wrapper">
          <TITLE>Cadastro de cartão</TITLE>
          <SUBTITLE>Informe os dados do cartão de crédito</SUBTITLE>
          <Loader color={ theme.companySecondary } isInline/>
        </div>
      </CONTAINER>
    </REGISTER>
  );

  return (
    <REGISTER>
      <CONTAINER>
        <div className="register__wrapper">
          <TITLE>Cadastro de cartão</TITLE>
          <SUBTITLE>Informe os dados do cartão de crédito</SUBTITLE>
          <ProgressBar percent={ progress }/>
          { currentStep === 1 && <CreditCardForms setCurrentStep={ setCurrentStep }/> }
          { currentStep === 2 && <AddressForms setCurrentStep={ setCurrentStep }/> }
          { currentStep === 3 && <ContactForms setCurrentStep={ setCurrentStep }/> }
        </div>
      </CONTAINER>
    </REGISTER>
  )
}

const CreditCardForms = ({ setCurrentStep }: ICardRegisterStepProps) => {
  const methods = useForm();
  const { setPaymentMethods } = useContext(PaymentMethodsContext);
  const [ selectedCardBrand, setSelectedCardBrand ] = useState<any>(null);
  const cardNumberWatch = methods.watch("cardNumber", "");
  const [ loading, setLoading ] = useState(false);

  const addCreditCard = (data: any) => {
    setLoading(true);

    const objToSend: IPostCreditCard = {
      cardNumber: removeBlankSpaceUtil(data.cardNumber),
      cardHolderName: data.cardHolderName,
      cardExpireDate: data.cardExpireDate,
      cvv: removeBlankSpaceUtil(data.cvv),
      active: true,
      document: data.document,
      brand: getCardBrandByNumberUtil(removeBlankSpaceUtil(data.cardNumber))
    }

    if (!objToSend.brand) return notifyUtil(ENotificationTypes.Error, "Erro ao definir bandeira do cartão!");

    postCreditCardService(objToSend)
      .then((res) => {
        setPaymentMethods({ type: EPaymentMethodsActionTypes.REGISTER_CARD_DATA, payload: { id: res.data } })
        setCurrentStep(2);
      })
      .catch(err => {
        setLoading(false)
      })

  }

  useEffect(() => {
    if (cardNumberWatch) setSelectedCardBrand(getCardBrandByNameUtil(getCardBrandByNumberUtil(removeBlankSpaceUtil(cardNumberWatch))));
    else setSelectedCardBrand(null);
  }, [ cardNumberWatch ])

  return (
    <InputProvider onValid={ addCreditCard } methods={ methods }>
      <div className={ "register__input-card" }>
        <Input
          name={ "cardNumber" }
          label={ "Número do cartão" }
          format={ "#### #### #### #####" }
          type={ EInputType.Mask }/>
        { selectedCardBrand && <img src={ selectedCardBrand } alt="ico"/> }
      </div>
      <ROW>
        <Input
          name={ "cardExpireDate" }
          label={ "Validade" }
          format={ "##/####" }
          type={ EInputType.Mask }/>
        <Input
          name={ "cvv" }
          label={ "Código de segurança" }
          format={ "####" }
          type={ EInputType.Mask }/>
      </ROW>

      <Input
        name={ "cardHolderName" }
        label={ "Nome impresso no cartão" }
        type={ EInputType.Text }/>
      <Input
        name={ "document" }
        label={ "CPF/CNPJ" }
        type={ EInputType.Document }/>

      <Button text={ "Continuar" } type={ "submit" } loading={ loading } disabled={ loading }/>
    </InputProvider>
  )
}

const AddressForms = ({ setCurrentStep }: ICardRegisterStepProps) => {
  const methods = useForm();
  const { paymentMethods, setPaymentMethods } = useContext(PaymentMethodsContext);
  const [ loading, setLoading ] = useState(false);
  const [ isFieldsDisabled, setIsFieldsDisabled ] = useState(false);

  const addAddress = (data: any) => {
    setLoading(true);

    const objToSend: IPostCreditCardAddress = {
      ...data
    }
    postCreditCardAddressService(paymentMethods.registerCardData.id, objToSend)
      .then(res => {
        setCurrentStep(3);
        setPaymentMethods({
          type: EPaymentMethodsActionTypes.REGISTER_CARD_DATA,
          payload: { ...paymentMethods.registerCardData, step: 3 }
        })
      })
      .catch(() => setLoading(false))
  }

  return (
    <InputProvider onValid={ addAddress } methods={ methods }>
      <ROW>
        <Input
          name={ "zipCode" }
          label={ "CEP" }
          format={ "#####-###" }
          type={ EInputType.Mask }/>
        <Input
          name={ "state" }
          label={ "Estado" }
          options={ stateListUtil }
          type={ EInputType.Select }/>
      </ROW>
      <Input
        name={ "city" }
        label={ "Cidade" }
        type={ EInputType.Text }/>
      <Input
        name={ "neighborhood" }
        label={ "Bairro" }
        type={ EInputType.Text }/>
      <Input
        name={ "street" }
        label={ "Endereço" }
        type={ EInputType.Text }/>
      <ROW>
        <Input
          name={ "streetNumber" }
          label={ "Número" }
          type={ EInputType.Number }/>
        <Input
          name={ "complement" }
          label={ "Complemento" }
          type={ EInputType.Text }/>
      </ROW>
      <Button text={ "Continuar" } type={ "submit" } loading={ loading } disabled={ loading }/>
    </InputProvider>
  )
}

const ContactForms = ({ setCurrentStep }: ICardRegisterStepProps) => {
  const methods = useForm();
  const { paymentMethods } = useContext(PaymentMethodsContext);
  const navigate = useNavigate();
  const [ loading, setLoading ] = useState(false);

  const addContact = (data: any) => {
    setLoading(true);

    const objToSend: IPostCreditCardContact = {
      ...data
    }

    postCreditCardContactService(paymentMethods.registerCardData.id, objToSend)
      .then(res => {
        notifyUtil(ENotificationTypes.Success, "Cartão cadastrado com sucesso!");
        navigate(ERoutes.PaymentMethodsList)
      })
      .catch(() => setLoading(false))
  }

  return (
    <InputProvider onValid={ addContact } methods={ methods }>
      <Input
        name={ "email" }
        label={ "E-mail" }
        type={ EInputType.Email }/>
      <Input
        name={ "phone" }
        label={ "Telefone" }
        format={ "(##) #####-####" }
        type={ EInputType.Mask }/>
      <Button text={ "Finalizar" } type={ "submit" } loading={ loading } disabled={ loading }/>

    </InputProvider>
  )
}

export {
  Register
}