import React, { useContext, useEffect, useState } from "react";
import { isDevUtil } from "../../utils/env.util";
import { HEADER, MENU } from "./Header.styles";
import { logoDefaultAsset } from "../../assets";
import { CONTAINER } from "../../styles/base.styles";
import { StoreContext } from "../../context/Store/StoreContext";
import { IconButton } from "../Button/Button";
import { EStoreActionTypes } from "../../context/Store/Store.interface";
import { BurguerIcon, CircleHelpIcon, DocumentIcon, LogoutIcon, ToolIcon, VehicleIcon, WalletIcon } from "../Icon/Icon";
import { useTheme } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { ERoutes } from "../../router/Router.interface";
import { IMenuProps } from "./Header.interface";

const Header = () => {
  const { store } = useContext(StoreContext);
  const theme = useTheme() as any;
  const [ isMenuOpened, setIsMenuOpened ] = useState<boolean>(false);

  return (
    <HEADER isDev={ isDevUtil } className={ store.isAuthenticated ? "aside" : "" }>
      <CONTAINER>
        <img src={ logoDefaultAsset } alt="logo"/>
        <IconButton
          icon={ <BurguerIcon color={ theme.gray }/> }
          action={ () => setIsMenuOpened(!isMenuOpened) }
          buttonSize={ 32 }/>
      </CONTAINER>
      {
        store.isAuthenticated && <Menu isMenuOpened={ isMenuOpened }/>
      }
    </HEADER>
  )
}

const Menu = ({ isMenuOpened }: IMenuProps) => {
  const { setStore } = useContext(StoreContext);
  const theme = useTheme() as any;
  const location = useLocation();
  const navigate = useNavigate();
  const [ currentPage, setCurrentPage ] = useState<ERoutes | null>(null);
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    setStore({ type: EStoreActionTypes.SET_IS_AUTHENTICATED, payload: false });
    window.location.reload();
  }

  useEffect(() => {
    switch (true) {
      case /mobby/i.test(location.pathname):
        setCurrentPage(ERoutes.Mobby);
        break;

      case /services/i.test(location.pathname):
        setCurrentPage(ERoutes.Services);
        break;

      case /transactions/i.test(location.pathname):
        setCurrentPage(ERoutes.Transactions);
        break;

      case /paymentMethods/i.test(location.pathname):
        setCurrentPage(ERoutes.PaymentMethodsList);
        break;

      case /help/i.test(location.pathname):
        setCurrentPage(ERoutes.Help);
        break;

      default:
        setCurrentPage(ERoutes.Mobby);
        break;
    }
  }, [ location ])

  return (
    <MENU className={ isMenuOpened ? "active" : "" }>
      <CONTAINER>
        <div className={ `menu__item ${ currentPage === ERoutes.Mobby ? "active" : "" }` }>
          <IconButton
            buttonSize={ 32 }
            icon={ <VehicleIcon color={ currentPage === ERoutes.Mobby ? theme.companySecondary : theme.gray }/> }
            action={ () => navigate(ERoutes.Mobby) }/>
          <span>
          Início
        </span>
        </div>
        <div className={ `menu__item ${ currentPage === ERoutes.Services ? "active" : "" }` }>
          <IconButton
            buttonSize={ 32 }
            icon={ <ToolIcon color={ currentPage === ERoutes.Services ? theme.companySecondary : theme.gray }/> }
            action={ () => navigate(ERoutes.Services) }/>
          <span>
          Serviços
        </span>
        </div>
        <div className={ `menu__item ${ currentPage === ERoutes.Transactions ? "active" : "" }` }>
          <IconButton
            buttonSize={ 26 }
            icon={
              <DocumentIcon color={ currentPage === ERoutes.Transactions ? theme.companySecondary : theme.gray }/> }
            action={ () => navigate(ERoutes.Transactions) }/>
          <span>
          Extrato
        </span>
        </div>
        <div className={ `menu__item ${ currentPage === ERoutes.PaymentMethodsList ? "active" : "" }` }>
          <IconButton
            buttonSize={ 32 }
            icon={
              <WalletIcon color={ currentPage === ERoutes.PaymentMethodsList ? theme.companySecondary : theme.gray }/> }
            action={ () => navigate(ERoutes.PaymentMethodsList) }/>
          <span>
          Formas de <br/> Pagamento
        </span>
        </div>
        <div className={ `menu__item ${ currentPage === ERoutes.Help ? "active" : "" }` }>
          <IconButton
            buttonSize={ 32 }
            icon={ <CircleHelpIcon color={ currentPage === ERoutes.Help ? theme.companySecondary : theme.gray }/> }
            action={ () => navigate(ERoutes.Help) }/>
          <span>
          Ajuda
        </span>
        </div>
        <div className={ `menu__item menu__item--logout` }>
          <IconButton
            buttonSize={ 32 }
            icon={ <LogoutIcon color={ theme.gray }/> }
            action={ logout }/>
          <span>
          Sair
        </span>
        </div>
      </CONTAINER>
    </MENU>
  )
}

export {
  Header
}
