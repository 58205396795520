import styled from "styled-components";

const MODAL = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    &__content {
      width: 100%;
      background-color: ${ ({ theme }) => theme.light };
      padding: 32px;
      border-radius: 8px;
      text-align: center;

      > h3 {
        font-size: 1.8rem;
        line-height: 2.8rem;
        margin-bottom: 12px;
        font-weight: 500;
      }

      > p {
        color: ${ ({ theme }) => theme.darkgray };
        font-size: 1.4rem;
        line-height: 1.9rem;

        span, strong {
          font-size: 1.4rem;
        }
      }
    }
  }
`

const LIST = styled.ul`
  list-style: none;
  padding: 0;
  max-width: 100%;

  > li {
    border-bottom: 1px solid ${ ({ theme }) => theme.lightgray };
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    text-align: initial;

    > span, > strong {
      font-size: 1.4rem;

      * {
        font-size: 1.4rem;
      }
    }

    > strong {
      display: inline-block;
      word-break: break-word;
      text-align: right;

      * {
        font-weight: bold;
      }
    }

    > span {
      margin-right: 16px;
    }

    &:first-of-type {
      border-top: 1px solid ${ ({ theme }) => theme.lightgray };
    }
  }
`

const CARDVEHICLE = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  border: 2px solid ${ ({ theme }) => theme.lightgray };
  border-radius: 8px;
  margin-bottom: 32px;

  > ul {
    width: calc(100% - 32px);
    list-style: none;
    padding: 0;
    margin: 0;

    > li {
      font-size: 1.2rem;
      color: ${ ({ theme }) => theme.darkgray };
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }

      > strong {
        font-size: 1.2rem;
        color: ${ ({ theme }) => theme.gray2 };
      }
    }
  }

`;

const CARDINSTALLMENTS = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px 0;

  .card {
    &__content {
      width: calc(100% - 32px);
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div {
        display: flex;
        flex-direction: column;

        > span {
          color: ${ ({ theme }) => theme.gray2 };
          font-size: 1.4rem;
          display: inline-block;

          &:first-of-type {
            font-size: 1.2rem;
            font-weight: bold;
            margin-bottom: 4px;
          }

        }

        > strong {
          color: ${ ({ theme }) => theme.darkgray };
          font-size: 1.4rem;
        }

        &:last-of-type {
          text-align: right;
        }
      }
    }
  }

`

export {
  MODAL,
  LIST,
  CARDVEHICLE,
  CARDINSTALLMENTS,
}