import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { appSettingsUtil, isTestUtil } from "../utils/env.util";
import { getStorageItemUtil, removeStorageItemUtil } from "../utils/storage.util";
import { notifyUtil } from "../utils/toast.util";
import { ENotificationTypes } from "../types/global.interface";
import { IAPIResponse } from "../types/api.interface";

const onRequest = (config: InternalAxiosRequestConfig) => {
  if (config.url?.toLowerCase() === "/user" || config.url?.toLowerCase() === "/user/login") return config;

  const token = getStorageItemUtil("accessToken");

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${ JSON.parse(token) }`
  }
  return config
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[request error] ${ JSON.stringify(error) }`)

  return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse<IAPIResponse> => {
  if (!response.data.success) {
    notifyUtil(ENotificationTypes.Error, response.data.error.message)
    throw new Error(response.data.error.message);
  }
  return response.data;
}

const onResponseError = (error: AxiosError) => {
  console[isTestUtil ? "log" : "error"](`[response error] ${ JSON.stringify(error) }`)
  if (error.response?.status === 401) {
    removeStorageItemUtil("accessToken");
    notifyUtil(ENotificationTypes.Error, "Sua sessão expirou, faça login novamente!")

    setTimeout(() => {
      window.location.reload()
    }, 1000);
  } else {
    notifyUtil(ENotificationTypes.Error, error.message)
  }

  return Promise.reject(error);
}

const apiUrlHandler = (): string => {
  return appSettingsUtil.apiUrl;
}

const api = axios.create({
  baseURL: apiUrlHandler(),
})

api.interceptors.request.use(onRequest, onRequestError);
api.interceptors.response.use(onResponse, onResponseError);

export default api;