import api from "./api";
import {
  ICreditCard,
  IPostCreditCard,
  IPostCreditCardAddress,
  IPostCreditCardContact,
  IPutCardDelete
} from "../pages/PaymentMethods/PaymentMethods.interface";
import { IAPIResponse } from "../types/api.interface";

const postCreditCard = (body: IPostCreditCard) => api.post(`/Cards`, body)

const getAllCreditCard = (): Promise<IAPIResponse<Array<ICreditCard>>> => api.get(`/Cards`)

const postCreditCardAddress = (id: string, body: IPostCreditCardAddress) => api.post(`/Cards/${ id }/Address`, body)

const postCreditCardContact = (id: string, body: IPostCreditCardContact) => api.post(`/Cards/${ id }/Contact`, body)

const putCreditCardDefault = (body: IPutCardDelete) => api.put(`/Cards/Default`, body)

const putCreditCardDelete = (body: IPutCardDelete) => api.put(`/Cards/Inactivate`, body)

export {
  postCreditCard as postCreditCardService,
  getAllCreditCard as getAllCreditCardService,
  postCreditCardAddress as postCreditCardAddressService,
  postCreditCardContact as postCreditCardContactService,
  putCreditCardDefault as putCreditCardDefaultService,
  putCreditCardDelete as putCreditCardDeleteService
}