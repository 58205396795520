import { useEffect, useState } from "react";
import { isProdUtil } from "../utils/env.util";
import { listenEventUtil, removeEventUtil } from "../utils/event.util";
import { saveStateOnStorageUtil } from "../utils/storage.util";

const useDevContextPersist = (store: any, moduleName: string) => {
  //DEV ENV ONLY
  const [ fireSaveStateOnSession, setFireSaveStateOnSession ] = useState<BeforeUnloadEvent | null | boolean>(null);

  //USADO APENAS EM ABIENTE DEV P/ EVITAR ERROS COM HOTRELOAD EM CONJUNTO COM REACT.CONTEXT.
  const setupEvents = () => {
    if (!isProdUtil) listenEventUtil("beforeunload", saveBackupStateOnSession, { capture: true });
  }

  //SALVA O ESTADO PARA EVITAR ERROS COM HOTRELOAD EM CONJUNTO COM REACT.CONTEXT.
  //USADO APENAS EM ABIENTE DEV
  const saveBackupStateOnSession = (event: BeforeUnloadEvent) => {
    if (!isProdUtil) {
      event.preventDefault();
      setFireSaveStateOnSession(event);
    }
  }

  useEffect(() => {
    setupEvents();

    return () => {
      removeEventUtil("beforeunload", saveBackupStateOnSession);
    }
  }, [])

  //USADO APENAS EM ABIENTE DEV P/ EVITAR ERROS COM HOTRELOAD EM CONJUNTO COM REACT.CONTEXT.
  useEffect(() => {
    if (fireSaveStateOnSession && !isProdUtil) {
      saveStateOnStorageUtil(moduleName, store);
      setFireSaveStateOnSession(null);
      if (fireSaveStateOnSession !== true) { // @ts-ignore
        (fireSaveStateOnSession as BeforeUnloadEvent).target.dispatchEvent(new Event("unload", { cancelable: true }));
      }
    }
  }, [ fireSaveStateOnSession ]);
}

export {
  useDevContextPersist
}