import React from 'react'
import { LOADER, PROGRESSBAR } from "./Loader.styles";
import { ILoaderProps, IProgressBarProps } from "./Loader.interface";
import { isDevUtil } from "../../utils/env.util";

const Loader = ({ color = "#FFF", isFullSize = false, text, isInline = false }: ILoaderProps) => {
  return (
    <LOADER isFullSize={ isFullSize } isInline={ isInline }>
      <svg viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" fill={ color }>
        <g transform="matrix(1,0,0,1,-1033.99,-1549.49)">
          <g transform="matrix(0.102914,0,0,0.102914,952.464,1421.73)">
            <path d="M1035.12,1241.35C900.961,1241.35 792.202,1350.11 792.202,1484.27C792.202,1618.34 901.051,1727.19 1035.12,1727.19C1169.29,1727.19 1278.04,1618.43 1278.04,1484.27L1197.5,1484.27C1197.5,1573.95 1124.8,1646.65 1035.12,1646.65C945.503,1646.65 872.743,1573.89 872.743,1484.27C872.743,1394.59 945.443,1321.89 1035.12,1321.89L1035.12,1241.35Z"/>
          </g>
        </g>
      </svg>
      { (text && isDevUtil) && <p>{ text }</p> }
    </LOADER>
  )
}

const ProgressBar = ({ percent, text }: IProgressBarProps) => {
  return (
    <PROGRESSBAR percent={ percent }>
      { text && <span>{ text }</span> }
    </PROGRESSBAR>
  )
}


export {
  Loader,
  ProgressBar
}