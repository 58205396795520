import styled from "styled-components";
import { mediaConfig } from "../../styles/themeConfig";

const CREDITCARD = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 3px solid ${ ({ theme }) => theme.lightgray2 };
  position: relative;
  margin-bottom: 24px;

  &.pending {
    background-color: ${ ({ theme }) => theme.inactive };

    .credit-card__name-and-role, .credit-card__number-date-brand {
      opacity: 0.5;
    }
  }

  > div {
    &:not(.credit-card__pending) {
      cursor: pointer;
    }
  }

  .credit-card {
    &__pending {
      h3 {
        margin-bottom: 8px;
      }

      h3, p {
        font-size: 1.4rem;
      }

      > div {
        padding: 0;
      }

      > button {
        background-color: transparent;
        appearance: none;
        padding: 0;
        font-weight: bold;
        font-size: 1.4rem;
        color: ${ ({ theme }) => theme.tertiary };
        margin-top: 8px;
        margin-left: 29px;
      }
    }

    &__name-and-role {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;

      > strong {
        font-size: 1.4rem;
        font-weight: bold;
        color: ${ ({ theme }) => theme.darkgray };
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100% - 96px);
      }

      > span {
        font-size: 1.2rem;
        color: ${ ({ theme }) => theme.darkgray };
      }
    }

    &__number-date-brand {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      > div {
        display: flex;
        flex-direction: column;

        > strong {
          font-size: 1.2rem;
          color: ${ ({ theme }) => theme.gray };
          margin-bottom: 4px;
        }

        > span {
          font-size: 1.4rem;
          color: ${ ({ theme }) => theme.darkgray };

          > strong {
            font-weight: bold;
            font-size: 2rem;
            margin-right: 8px;
            letter-spacing: 1.86px;
            position: relative;
            top: 4px;
          }
        }
      }

      > img {
        object-fit: contain;

        ${ mediaConfig.desktop } {
          height: 10px;
        }
      }
    }
  }
`;

export {
  CREDITCARD
}