import { CREDITCARD } from "./CreditCard.styles";
import { ICreditCardProps } from "./CreditCard.interface";
import { useContext } from "react";
import { useTheme } from "styled-components";
import { getCardBrandByNameUtil } from "../../utils/creditCard.util";
import { Warning } from "../Warning/Warning";
import { PaymentMethodsContext } from "../../context/PaymentMethods/PaymentMethodsContext";
import { EPaymentMethodsActionTypes } from "../../context/PaymentMethods/PaymentMethodsContext.interface";
import { useNavigate } from "react-router-dom";
import { ERoutes } from "../../router/Router.interface";

const CreditCard = ({ cardInfo, handleOpenCardActions }: ICreditCardProps) => {
  const { setPaymentMethods } = useContext(PaymentMethodsContext);
  const navigate = useNavigate();
  const theme = useTheme() as any;


  const _continueRegisterHandler = () => {
    setPaymentMethods({ type: EPaymentMethodsActionTypes.REGISTER_CARD_DATA, payload: cardInfo })
    navigate(ERoutes.PaymentMethodsRegister)
  }

  return (
    <CREDITCARD className={ !cardInfo.isCompleted ? "pending" : "" }>
      <div onClick={ () => handleOpenCardActions(cardInfo) }>
        <div className="credit-card__name-and-role">
          <strong>{ cardInfo.name }</strong>
          { cardInfo.isDefault && <span>Cartão principal</span> }
        </div>
        <div className="credit-card__number-date-brand">
          <div>
            <strong>Nº final do cartão</strong>
            <span><strong>****</strong>{ cardInfo.cardNumber }</span>
          </div>
          <div>
            <strong>Validade</strong>
            <span>{ cardInfo.expirationDate }</span>
          </div>
          <img src={ getCardBrandByNameUtil(cardInfo.brand) } alt="Card Brand"/>
        </div>
      </div>
      { !cardInfo.isCompleted &&
        <div className={ "credit-card__pending" }>
          <hr/>
          <Warning
            message={
              <p>Para que possa usar este cartão como meio de pagamento, é necessário fornecer mais algumas
                informações.</p>
            }
            title={ "Cadastro incompleto" }/>
          <button onClick={ _continueRegisterHandler }>Continuar cadastro</button>
        </div>
      }
    </CREDITCARD>
  )
}

export { CreditCard };