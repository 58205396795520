import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { ERoutes, IPrivateRouteProps } from "./Router.interface";
import { Login } from "../pages/Login/Login";
import { Mobby } from "../pages/Mobby/Mobby";
import { StoreContext } from "../context/Store/StoreContext";
import React, { useContext } from "react";
import { Header } from "../components/Header/Header";
import { Register } from "../pages/Register/Register";
import { LAYOUT } from "../styles/base.styles";
import { ForgotPassword } from "../pages/Login/ForgotPassword";
import { ChangePasswordByForgot } from "../pages/Login/ChangePasswordByForgot";
import { ConfirmEmail } from "../pages/ConfirmEmail/ConfirmEmail";
import { List as PaymentMethodsList } from "../pages/PaymentMethods/List";
import { Register as PaymentMethodsRegister } from "../pages/PaymentMethods/Register";
import { PaymentMethodsProvider } from "../context/PaymentMethods/PaymentMethodsContext";

const Layout = (): JSX.Element => {
  const { store } = useContext(StoreContext);
  return (
    <LAYOUT className={ `layout ${ store.isAuthenticated ? "authed" : "" }` }>
      <Header/>
      <main>
        <Outlet/>
      </main>
    </LAYOUT>
  )
}

const PrivateRoute = ({ element }: IPrivateRouteProps): JSX.Element => {
  const { store } = useContext(StoreContext);

  if (!store.isAuthenticated) return <Navigate to={ ERoutes.Login } replace={ true }/>

  return (
    <React.Fragment>
      { element }
    </React.Fragment>
  )
}

const PublicRoute = ({ element }: IPrivateRouteProps): JSX.Element => {
  const { store } = useContext(StoreContext);

  if (store.isAuthenticated) return <Navigate to={ ERoutes.Mobby } replace={ true }/>

  return (
    <React.Fragment>
      { element }
    </React.Fragment>
  )
}

const Router = (): JSX.Element => {
  const { store } = useContext(StoreContext);

  return (
    <Routes>
      <Route path={ ERoutes.Basename } element={ <Layout/> }>
        <Route index element={ <PublicRoute element={ <Login/> }/> }/>
        <Route path={ ERoutes.Mobby } element={ <PrivateRoute element={ <Mobby/> }/> }/>
        <Route path={ ERoutes.ForgotPassword } element={ <PublicRoute element={ <ForgotPassword/> }/> }/>
        <Route
          path={ ERoutes.ChangePasswordByForgot }
          element={ <PublicRoute element={ <ChangePasswordByForgot/> }/> }/>
        <Route path={ ERoutes.Register } element={ <PublicRoute element={ <Register/> }/> }/>
        <Route path={ ERoutes.ConfirmEmail } element={ <PublicRoute element={ <ConfirmEmail/> }/> }/>
        <Route
          path={ ERoutes.PaymentMethodsList }
          element={ <PrivateRoute
            element={ <PaymentMethodsProvider><PaymentMethodsList/></PaymentMethodsProvider> }/> }/>
        <Route
          path={ ERoutes.PaymentMethodsRegister }
          element={ <PrivateRoute
            element={ <PaymentMethodsProvider><PaymentMethodsRegister/></PaymentMethodsProvider> }/> }/>
      </Route>
    </Routes>
  )
}

export {
  Router
}