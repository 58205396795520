import styled from "styled-components";
import { mediaConfig } from "../../styles/themeConfig";
import { SUBTITLE } from "../../styles/base.styles";

const LIST = styled.section`
  ${ mediaConfig.desktop } {
    padding-top: 48px;
  }

  .list {
    &__wrapper {
      ${ mediaConfig.desktop } {
        height: calc(100vh);
        width: 100%;
        max-width: 488px;
        margin: 0 auto;
      }

      > ${ SUBTITLE } {
        margin-bottom: 24px;
      }
    }

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > button {
        display: flex;
        align-items: center;
        background-color: transparent;
        appearance: none;
        padding: 0;
        font-weight: bold;
        font-size: 1.4rem;
        color: ${ ({ theme }) => theme.tertiary };

        > svg {
          margin-right: 8px;
          width: 12px;
          height: 12px;
        }
      }
    }

    &__warning {
      padding: 32px 24px;
      padding-top: 16px;
      border-radius: 8px;
      background-color: ${ ({ theme }) => theme.lightgray2 };

      > div {
        &:not(.warn) {
          display: flex;
          justify-content: flex-start;
          padding-left: 30px;
        }
      }
    }

    &__modal-actions-body {

      .set-default-card {
        display: flex;
        justify-content: space-between;

        > div {
          text-align: left;
          padding-right: 16px;

          > h4 {
            font-size: 1.4rem;
            color: ${ ({ theme }) => theme.darkgray };
            margin-bottom: 4px;
          }

          > p {
            font-size: 1.2rem;
            color: ${ ({ theme }) => theme.gray2 };
          }
        }

        > label {
          background-color: transparent;
          width: 80px;

          > div {
            margin-left: auto;
          }
        }
      }

      > button {
        display: flex;
        align-items: center;
        background-color: transparent;
        appearance: none;
        padding: 0;
        font-weight: bold;
        font-size: 1.4rem;
        color: ${ ({ theme }) => theme.invalid };

        > svg {
          margin-right: 8px;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
`;

const REGISTER = styled.section`
  ${ mediaConfig.desktop } {
    padding-top: 48px;
  }

  h5 {
    font-weight: bold;
    font-size: 1.2rem;
    color: ${ ({ theme }) => theme.gray2 };
    margin-bottom: 8px;
  }

  .register {
    &__wrapper {
      ${ mediaConfig.desktop } {
        height: calc(100vh);
        width: 100%;
        max-width: 488px;
        margin: 0 auto;
      }

      > ${ SUBTITLE } {
        margin-bottom: 24px;
      }

      form {
        margin-top: 24px;
      }
    }

    &__card-brands {
      list-style: none;
      display: flex;
      justify-content: space-between;
      padding: 0;
      margin-bottom: 32px;

      > li {
        width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 4px;
        background-color: ${ ({ theme }) => theme.lightgray2 };

      }

      > div {
        width: 100%;
      }
    }

    &__input-card {
      position: relative;

      > img {
        position: absolute;
        top: 24px;
        right: 0;
      }
    }

    &__step {
      display: none;

      &.active {
        display: block;
      }
    }
  }`;

export {
  LIST,
  REGISTER,
}