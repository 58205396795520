import { MODALASIDE, MODALCONFIRM, MODALINFO, POPUPCONFIRM } from "./Modal.styles";
import { IModalAsideProps, IModalConfirmProps, IModalInfoProps } from "./Modal.interface";
import { BUTTONGRADIENT, BUTTONOUTLINE } from "../../styles/base.styles";
import { Loader } from "../Loader/Loader";
import { IconButton } from "../Button/Button";
import { TimesIcon } from "../Icon/Icon";

const ModalInfo = ({ title, message, isOpen, onRefuse, icon, loading, isIconCircle }: IModalInfoProps) => {
  return (
    <MODALINFO className={ isOpen ? "active" : "" }>
      <div className="modal__content">
        { icon && <div className={ `modal__icon ${ isIconCircle ? "" : "modal__icon--bordered" }` }>{ icon }</div> }
        <div className="modal__header">
          <h3>{ title }</h3>
        </div>
        <div className="modal__body">
          { message }
        </div>
        <div className="modal__actions">
          <BUTTONOUTLINE>
            <button disabled={ loading } onClick={ onRefuse }>{ loading ? <Loader/> : "OK" }</button>
          </BUTTONOUTLINE>
        </div>
      </div>
    </MODALINFO>
  )
}

const ModalConfirm = ({
  icon,
  title,
  message,
  confirmText,
  refuseText,
  isOpen,
  isDanger,
  isWarning,
  onConfirm,
  onRefuse,
  loading
}: IModalConfirmProps) => {
  return (
    <MODALCONFIRM className={ isOpen ? "active" : "" }>
      <div className="modal__content">
        { icon && <div className={ "modal__icon" }>{ icon }</div> }
        <div className="modal__header">
          <h3>{ title }</h3>
        </div>
        <div className="modal__body">
          { message }
        </div>
        <div className="modal__actions">
          <BUTTONGRADIENT isDanger={ isDanger } isWarning={ isWarning } isCenter>
            <button disabled={ loading } onClick={ onConfirm }>{ loading ?
              <Loader/> : (confirmText || "Confirmar") }</button>
          </BUTTONGRADIENT>
          <button disabled={ loading } onClick={ onRefuse }>
            { refuseText || "Cancelar" }
          </button>
        </div>
      </div>
    </MODALCONFIRM>
  )
}

const PopupConfirm = ({
  icon,
  title,
  message,
  confirmText,
  refuseText,
  isOpen,
  isDanger,
  isWarning,
  onConfirm,
  onRefuse,
  loading
}: IModalConfirmProps) => {
  return (
    <POPUPCONFIRM className={ isOpen ? "active" : "" }>
      <div className="popup__content">
        { icon && <div className={ "popup__icon" }>{ icon }</div> }
        <div className="popup__header">
          <h3>{ title }</h3>
        </div>
        <div className="popup__body">
          { message }
        </div>
        <div className="popup__actions">
          {
            onConfirm &&
            <BUTTONGRADIENT isDanger={ isDanger } isWarning={ isWarning } isCenter>
              <button disabled={ loading } onClick={ onConfirm }>{ loading ?
                <Loader/> : (confirmText || "Confirmar") }</button>
            </BUTTONGRADIENT>
          }
          <button disabled={ loading } onClick={ onRefuse }>
            { refuseText || "Fechar" }
          </button>
        </div>
      </div>
    </POPUPCONFIRM>
  )
}

const ModalAside = ({
  title,
  subtitle,
  message,
  confirmText,
  refuseText,
  isOpen,
  isDanger,
  isWarning,
  onConfirm,
  onRefuse,
  loading,
  lg = false,
  md = false,
  observation
}: IModalAsideProps) => {

  return (
    <MODALASIDE isLarge={ lg } isMedium={ md }>
      { isOpen && <div className="modal-aside__backdrop"></div> }
      <div className={ `modal-aside__content${ isOpen ? " open" : "" }` }>
        <div className="title-and-close">
          <div className="title">
            <h2>{ title }</h2>
            <h5>{ subtitle }</h5>
          </div>
          <div className="close">
            <IconButton action={ onRefuse } buttonSize={ 40 } icon={ <TimesIcon color={ "gray" }/> }/>
          </div>
        </div>
        {
          observation &&
          <div className="observation">
            <p>{ observation }</p>
          </div>
        }
        <div className="body">
          { message }
          <div className="modal-aside__actions">
            {
              onConfirm &&
              <BUTTONGRADIENT isDanger={ isDanger } isWarning={ isWarning } isCenter>
                <button disabled={ loading } onClick={ onConfirm }>{ loading ?
                  <Loader/> : (confirmText || "Confirmar") }</button>
              </BUTTONGRADIENT>
            }
            <button disabled={ loading } onClick={ onRefuse }>
              { refuseText || "Fechar" }
            </button>
          </div>
        </div>
      </div>
    </MODALASIDE>
  )
}

const ModalResponsive = (props: IModalConfirmProps) => {
  const isMobile = window.innerWidth <= 800;

  if (isMobile) return (
    <PopupConfirm { ...props } />
  )

  return (
    <ModalAside { ...props } md/>
  )
}

export {
  ModalInfo,
  ModalConfirm,
  ModalAside,
  ModalResponsive,
  PopupConfirm
}