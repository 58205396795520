import styled from "styled-components";
import { IModalAsideSC } from "./Modal.interface";
import { BUTTONGRADIENT } from "../../styles/base.styles";
import { mediaConfig } from "../../styles/themeConfig";


const MODAL = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -999;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease-in-out;

  &.active {
    z-index: 10;
    background-color: rgba(51, 63, 85, 0.5);

    .modal__content {
      transform: scaleX(1) scaleY(1);
      opacity: 1;
    }
  }

  .modal {
    &__content {
      width: 100%;
      background-color: ${ ({ theme }) => theme.light };
      padding: 32px;
      border-radius: 8px;
      text-align: center;
      transition: var(--transition-all);
      transform: scaleX(0.8) scaleY(1.2);
      opacity: 0;

      ${ mediaConfig.desktop } {
        max-width: 500px;
      }
    }

    &__header {
      > h3 {
        text-align: left;
        font-size: 2rem;
        line-height: 2.8rem;
        margin-bottom: 24px;
        font-weight: bold;
        color: ${ ({ theme }) => theme.dark };
      }
    }

    &__body {
      > p {
        text-align: left;
        font-size: 1.4rem;
        line-height: 2rem;
        color: ${ ({ theme }) => theme.darkgray };

        * {
          font-size: 1.4rem;
        }
      }
    }

    &__icon {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-inline: auto;
      margin-bottom: 16px;

      > svg {
        width: 64px;
        height: 64px;
      }

      &--bordered {
        border: 2px solid ${ ({ theme }) => theme.gray };

        > svg {
          width: 32px;
          height: 32px;
        }
      }
    }

    &__actions {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      ${ mediaConfig.desktop } {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
      }

      button {
        text-transform: uppercase;

        ${ mediaConfig.mobile } {
          width: 100%;
        }
      }

      > button {
        margin-top: 16px;
        color: ${ ({ theme }) => theme.gray };
        font-size: 1.2rem;
        line-height: 1.9rem;
        background-color: transparent;
        padding: 0;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }

`;

const MODALINFO = styled(MODAL)``;

const MODALCONFIRM = styled(MODAL)``;

const POPUPCONFIRM = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -999;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  &.active {
    z-index: 10;
    background-color: rgba(51, 63, 85, 0.5);

    .popup__content {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .popup {
    &__content {
      width: 100%;
      background-color: ${ ({ theme }) => theme.light };
      padding: 32px;
      border-radius: 16px 16px 0 0;
      text-align: center;
      transition: var(--transition-all);
      transform: translateY(100%);
      opacity: 0;
    }

    &__header {
      > h3 {
        text-align: left;
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin-bottom: 24px;
        font-weight: bold;
        color: ${ ({ theme }) => theme.dark };
      }
    }

    &__body {
      > p {
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: ${ ({ theme }) => theme.darkgray };

        * {
          font-size: 1.4rem;
        }
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-inline: auto;
      margin-bottom: 20px;

      > svg {
      }

      &--bordered {
        border: 2px solid ${ ({ theme }) => theme.gray };

        > svg {
          width: 32px;
          height: 32px;
        }
      }
    }

    &__actions {
      margin-top: 40px;

      button {
        text-transform: uppercase;
      }

      > button {
        color: ${ ({ theme }) => theme.gray };
        font-size: 1.2rem;
        line-height: 1.9rem;
        background-color: transparent;
        padding: 0;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 16px;

      }
    }
  }
`;

const MODALASIDE = styled.div<IModalAsideSC>`

  ${ BUTTONGRADIENT } {
    margin-top: 16px;
  }


  .modal-aside {

    &__backdrop {
      background-color: rgba(51, 63, 85, 0.3);
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 99;
    }

    &__content {
      background-color: ${ ({ theme }) => theme.light };
      width: ${ ({ isLarge, isMedium }) => isLarge ? "calc(100vw - 200px)" : isMedium ? "50vw" : "500px" };
      height: 100vh;
      overflow-y: auto;
      position: fixed;
      top: 0;
      right: ${ ({ isLarge, isMedium }) => isLarge ? "calc((100vw - 200px) * -1)" : isMedium ? "-50vw" : "-500px" };
      z-index: 999;
      padding: 40px;
      transition: var(--transition-all);
      box-shadow: -16px 0 24px 0 rgba(0, 0, 0, 0.1);

      &.open {
        right: 0;
      }

      .observation {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid ${ ({ theme }) => theme.gray3 };

        > p {
          font-size: 1.4rem;
          color: ${ ({ theme }) => theme.gray2 };
        }
      }

      .body {
        width: 100%;

        .configuration {
          &__header-data {
            display: flex;
            flex-direction: column;

            > span {
              color: ${ ({ theme }) => theme.color6Opaque };
              font-weight: bold;
              font-size: 1.1rem;
              margin-bottom: 4px;
            }

            > strong {
              color: ${ ({ theme }) => theme.gray };
              font-size: 1.6rem;
            }
          }

          &__status {
            margin-bottom: 24px;
            height: 56px;
            display: flex;
            align-items: center;
            padding: 0 16px;
            border-radius: 4px;
            background-color: ${ ({ theme }) => theme.color10 };

            .pending {
              border-radius: 50%;
              width: 24px;
              height: 24px;
              background-color: ${ ({ theme }) => theme.light };

              > svg {
                width: 100%;
                height: 100%;
                color: ${ ({ theme }) => theme.warning } !important;
              }
            }

            > span {
              display: inline-block;
              margin: 16px;
              color: ${ ({ theme }) => theme.gray };
              font-size: 1.2rem;
            }
          }

        }
      }

      .title-and-close {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid ${ ({ theme }) => theme.gray3 };
        margin-bottom: 24px;
        padding-bottom: 24px;


        .title {
          display: flex;
          align-items: center;

          h2 {
            font-size: 2.2rem;
            margin-bottom: 8px;
            color: ${ ({ theme }) => theme.color7 };
            font-weight: 600;
          }

          h5 {
            font-size: 1.6rem;
            margin-bottom: 24px;
            color: ${ ({ theme }) => theme.gray };
            font-weight: 600;
          }
        }

        .close {
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .integration-detail {
        &__code {
          > div {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 24px;

            > span {
              font-size: 1.4rem;
              text-transform: uppercase;
              font-weight: bold;
              margin-bottom: 8px;
              color: ${ ({ theme }) => theme.gray };
            }

            > code {
              border-radius: 4px;
              width: 100%;
              background-color: rgba(222, 222, 222, 0.3);
              color: crimson;
              padding: 8px;
              font-size: 1.2rem;
              line-break: anywhere;
            }
          }
        }
      }
    }

    &__actions {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;

      button {
        text-transform: uppercase;
      }

      > button {
        color: ${ ({ theme }) => theme.gray };
        font-size: 1.2rem;
        line-height: 1.9rem;
        background-color: transparent;
        padding: 0;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 16px;

      }
    }
  }
`

export {
  MODALINFO,
  MODALCONFIRM,
  POPUPCONFIRM,
  MODALASIDE,
}