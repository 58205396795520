import { LIST } from "./PaymentMethods.styles";
import { CONTAINER, SUBTITLE, TITLE } from "../../styles/base.styles";
import { Warning } from "../../components/Warning/Warning";
import { Button } from "../../components/Button/Button";
import { ERoutes } from "../../router/Router.interface";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import {
  getAllCreditCardService,
  putCreditCardDefaultService,
  putCreditCardDeleteService
} from "../../services/paymentMethods.service";
import { ICreditCard, IPutCardDelete } from "./PaymentMethods.interface";
import { Loader } from "../../components/Loader/Loader";
import { useTheme } from "styled-components";
import { CreditCard } from "../../components/CreditCard/CreditCard";
import { PlusIcon } from "../../components/Icon/Icon";
import { ModalConfirm, ModalResponsive } from "../../components/Modal/Modal";
import { SWITCHER } from "../../components/Input/Input.styles";
import { PaymentMethodsContext } from "../../context/PaymentMethods/PaymentMethodsContext";
import { EPaymentMethodsActionTypes } from "../../context/PaymentMethods/PaymentMethodsContext.interface";
import { LIST as DEFAULTLIST } from "../../styles/components.styles"
import { notifyUtil } from "../../utils/toast.util";
import { ENotificationTypes } from "../../types/global.interface";

const List = () => {
  const navigate = useNavigate();
  const theme = useTheme() as any;
  const { setPaymentMethods } = useContext(PaymentMethodsContext);
  const [ creditCardList, setCreditCardList ] = useState<Array<ICreditCard> | null>(null);
  const [ loading, setLoading ] = useState(true);
  const [ modalLoading, setModalLoading ] = useState(false);
  const [ reloadList, setReloadList ] = useState(false);
  const [ modalCardActions, setModalCardActions ] = useState(false);
  const [ modalDeleteCard, setModalDeleteCard ] = useState(false);
  const [ selectedCard, setSelectedCard ] = useState<ICreditCard | null>(null);

  useEffect(() => {
    setLoading(true);
    setPaymentMethods({ type: EPaymentMethodsActionTypes.REGISTER_CARD_DATA, payload: null })

    getAllCreditCardService()
      .then(res => {
        if (res.data.length === 0) setCreditCardList(null);
        else setCreditCardList(res.data)
      })
      .catch(error => console.log({ error }))
      .finally(() => setLoading(false))
  }, [ reloadList ])

  const handleOpenCardActions = (creditCard: ICreditCard) => {
    setSelectedCard(creditCard);
    setModalCardActions(true);
  }

  const deleteCard = () => {
    setModalLoading(true)
    const objToSend: IPutCardDelete = {
      cardId: selectedCard!.id,
    }

    putCreditCardDeleteService(objToSend)
      .then(res => {
        setSelectedCard(null);
        setModalCardActions(false);
        setReloadList(!reloadList);
      })
      .finally(() => {
        setModalLoading(false);
        setModalDeleteCard(false)
        setModalCardActions(false)
      })
  }

  const setDefaultCard = () => {
    if (!selectedCard?.isCompleted) return notifyUtil(ENotificationTypes.Warning, "Ops!, Você não pode definir um cartão de crédito com cadastro incompleto, como padrão.")
    
    setModalLoading(true)
    const objToSend: IPutCardDelete = {
      cardId: selectedCard!.id,
    }

    putCreditCardDefaultService(objToSend)
      .then(res => {
        setSelectedCard({ ...selectedCard!, isDefault: true });
        setReloadList(!reloadList);
      })
      .finally(() => setModalLoading(false))
  }

  if (loading) return (
    <LIST>
      <CONTAINER>
        <div className="list__wrapper">
          <TITLE>Meus cartões</TITLE>
          <SUBTITLE>Aqui ficam os cartões cadastrados por você para realizar as compras e/ou pagamentos.</SUBTITLE>
          <Loader color={ theme.companySecondary } isInline/>
        </div>
      </CONTAINER>
    </LIST>
  )

  if (!creditCardList) return (
    <LIST>
      <CONTAINER>
        <div className="list__wrapper">
          <TITLE>Meus cartões</TITLE>
          <SUBTITLE>Aqui ficam os cartões cadastrados por você para realizar as compras e/ou pagamentos.</SUBTITLE>
          <div className={ "list__warning" }>
            <Warning
              title={ "Ops! Você ainda possui cartão de crédito cadastrado." }
              message={ <p>Para fazer pagamentos e compras em nossa plataforma, é necessário ter pelo menos um cartão de
                crédito cadastrado. Cadastre seu cartão e comece a usar os nossos serviços.</p> }/>

            <Button
              text={ "Cadastrar cartão" }
              type={ "button" }
              action={ () => navigate(ERoutes.PaymentMethodsRegister) }/>
          </div>
        </div>
      </CONTAINER>
    </LIST>
  )

  return (
    <LIST>
      <CONTAINER>
        <div className="list__wrapper">
          <div className="list__title">
            <TITLE>Meus cartões</TITLE>
            <button onClick={ () => navigate(ERoutes.PaymentMethodsRegister) }><PlusIcon color={ theme.tertiary }/>Adicionar
              cartão
            </button>
          </div>
          <SUBTITLE>Aqui ficam os cartões cadastrados por você para realizar as compras e/ou pagamentos.</SUBTITLE>
          {
            creditCardList.map((creditCard) => <CreditCard
              key={ creditCard.id }
              cardInfo={ creditCard }
              handleOpenCardActions={ handleOpenCardActions }
              setDefaultCardFn={ deleteCard }/>)
          }
        </div>
      </CONTAINER>

      <ModalResponsive
        title={ "Ações do cartão de crédito" }
        message={
          <div className={ "list__modal-actions-body" }>
            <DEFAULTLIST>
              <li style={ { borderTop: "none", paddingTop: 4 } }>
                <span>Número final do cartão</span>
                <strong
                  style={ {
                    display: "flex",
                    alignItems: "center"
                  } }>
                  <sup style={ { fontSize: "1.8rem", position: "relative", top: "2px", marginRight: 4 } }>**** </sup>
                  { selectedCard?.cardNumber }
                </strong>
              </li>
              <li>
                <span>Titular do cartão</span>
                <strong>{ selectedCard?.name }</strong>
              </li>
              <li>
                <span>Validade</span>
                <strong>{ selectedCard?.expirationDate }</strong>
              </li>
              <li style={ { display: "block" } }>
                <div className={ "set-default-card" }>
                  <div>
                    <h4>Definir como principal</h4>
                    <p>Ao definir o cartão como principal, todas as cobranças serão realizada neste cartão.</p>
                  </div>
                  <SWITCHER
                    active={ selectedCard ? selectedCard!.isDefault : false }
                    htmlFor={ "isDefault" }>
                    <input
                      onChange={ setDefaultCard }
                      disabled={ selectedCard ? selectedCard!.isDefault : false }
                      id={ "isDefault" }
                      type="checkbox"/>
                    <div></div>
                  </SWITCHER>
                </div>
              </li>
            </DEFAULTLIST>
          </div>
        }
        onConfirm={ () => {
          setModalDeleteCard(true);
          setModalCardActions(false);
        } }
        confirmText={ "Excluir cartão" }
        loading={ modalLoading }
        isWarning
        onRefuse={ () => {
          setSelectedCard(null);
          setModalCardActions(false)
        } }
        isOpen={ modalCardActions }/>

      <ModalConfirm
        title={ "Atenção!" }
        message={
          <p>
            Ao excluir o cartão (**** { selectedCard?.cardNumber }), você não poderá fazer pagamentos e compras com este
            cartão em nossa
            plataforma. <strong>Caso tenha cobranças recorrentes, é necessário ter pelo menos um cartão cadastrado para
            que o serviço não seja bloqueado</strong>.<br/><br/>
            Deseja mesmo excluir?
          </p>
        }
        isOpen={ modalDeleteCard }
        confirmText={ "Excluir cartão" }
        onConfirm={ deleteCard }
        loading={ modalLoading }
        isDanger
        refuseText={ "Não quero excluir" }
        onRefuse={ () => {
          setModalDeleteCard(false);
          setModalCardActions(true)
        } }/>
    </LIST>
  )
}

export { List }