import React from "react";
import { ICreditCard } from "../../pages/PaymentMethods/PaymentMethods.interface";

export interface IPaymentMethodsContext {
  setPaymentMethods: React.Dispatch<IPaymentMethodsAction>;
  paymentMethods: IPaymentMethodsData;
}

export interface IPaymentMethodsData {
  registerCardData: ICreditCard | null;
}

export interface IPaymentMethodsProviderProps {
  children: React.ReactNode;
}

export interface IPaymentMethodsAction {
  type: EPaymentMethodsActionTypes;
  payload: any;
}


export enum EPaymentMethodsActionTypes {
  REGISTER_CARD_DATA = "REGISTER_CARD_DATA",
  SET_STATE_BY_SESSION_BACKUP = "SET_STATE_BY_SESSION_BACKUP",
}

