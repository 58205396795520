import styled, { keyframes } from "styled-components";
import { ILoaderSC, IProgressBarSC } from "./Loader.interface";


const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LOADER = styled.div<ILoaderSC>`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ ({ isFullSize }) => isFullSize ? "height: 100vh; width: 100vw;" : null };
  ${ ({ isInline }) => isInline ? "height: auto; width: auto; align-items: flex-start;" : null };

  > svg {
    animation: ${ rotate } 1000ms linear infinite;
    width: 24px;
    height: 24px;
  }

  > p {
    text-align: center;
    font-weight: bold;
    color: ${ ({ theme }) => theme.gray };
    margin-top: 16px;
    font-size: 1.2rem;
    max-width: 200px;
    margin-inline: auto;
  }
`

const PROGRESSBAR = styled.div<IProgressBarSC>`
  width: 100%;
  height: 1px;
  background-color: ${ ({ theme }) => theme.lightgray };
  position: relative;
  overflow: visible;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: -2px;
    border-radius: 2px;
    height: 4px;
    background-color: ${ ({ theme }) => theme.companySecondary };
    transition: var(--transition-all);
    width: ${ ({ percent }) => percent }%;
  }
`;

export { LOADER, PROGRESSBAR }