import styled from "styled-components";
import { CONTAINER, SUBTITLE } from "../../styles/base.styles";
import { mediaConfig } from "../../styles/themeConfig";

const LOGIN = styled.section`
  padding: 0 8px;

  &::before {
    content: "";
    height: 100vh;
    width: 50vw;
    position: fixed;
    right: 0;
    top: 0;
    background: ${ ({ theme }) => theme.primary };

    ${ mediaConfig.tablet } {
      display: none;
    }
  }

  > ${ CONTAINER } {
    .login {
      &__content {
        ${ mediaConfig.desktop } {
          max-width: 384px;
        }

        > ${ SUBTITLE } {
          margin-bottom: 48px;
        }

        .input-group__input--checkbox {
          display: flex;
          justify-content: space-between;

          a {
            color: ${ ({ theme }) => theme.valid };
          }
        }

        button {
          width: 100%;
        }
      }

      &__register-text {
        text-align: center;
        font-weight: bold;
        color: ${ ({ theme }) => theme.darkgray };
        font-size: 1.4rem;
        margin-top: 40px;

        a {
          font-weight: bold;
          color: ${ ({ theme }) => theme.valid };
        }
      }
    }
  }
`;

const FORGOTPASSWORD = styled.section`
  padding: 0 8px;

  &::before {
    content: "";
    height: 100vh;
    width: 50vw;
    position: fixed;
    right: 0;
    top: 0;
    background: ${ ({ theme }) => theme.primary };

    ${ mediaConfig.tablet } {
      display: none;
    }
  }

  > ${ CONTAINER } {
    .forgot-password {
      &__content {
        ${ mediaConfig.desktop } {
          max-width: 384px;
        }

        > ${ SUBTITLE } {
          margin-bottom: 48px;
        }

        .input-group__input--checkbox {
          display: flex;
          justify-content: space-between;

          a {
            color: ${ ({ theme }) => theme.valid };
          }
        }
      }

      &__register-text {
        text-align: center;
        font-weight: bold;
        color: ${ ({ theme }) => theme.darkgray };
        font-size: 1.4rem;
        margin-top: 40px;
      }
    }
  }
`;

const CHANGEPASSWORDBYFORGOT = styled.section`
  padding: 0 8px;

  &::before {
    content: "";
    height: 100vh;
    width: 50vw;
    position: fixed;
    right: 0;
    top: 0;
    background: ${ ({ theme }) => theme.primary };

    ${ mediaConfig.tablet } {
      display: none;
    }
  }

  > ${ CONTAINER } {
    .change-password-by-forgot {
      &__content {
        ${ mediaConfig.desktop } {
          max-width: 384px;
        }

        > ${ SUBTITLE } {
          margin-bottom: 48px;
        }

        .input-group__input--checkbox {
          display: flex;
          justify-content: space-between;

          a {
            color: ${ ({ theme }) => theme.valid };
          }
        }
      }

      &__register-text {
        text-align: center;
        font-weight: bold;
        color: ${ ({ theme }) => theme.darkgray };
        font-size: 1.4rem;
        margin-top: 40px;

        a {
          font-weight: bold;
          color: ${ ({ theme }) => theme.valid };
        }
      }
    }
  }
`;

export {
  LOGIN,
  FORGOTPASSWORD,
  CHANGEPASSWORDBYFORGOT
}