import React, { createContext, Reducer, useEffect, useReducer, useState } from "react";
import { useTheme } from "styled-components";
import {
  EPaymentMethodsActionTypes,
  IPaymentMethodsAction,
  IPaymentMethodsData,
  IPaymentMethodsProviderProps
} from "./PaymentMethodsContext.interface";
import { getStateFromStorageUtil } from "../../utils/storage.util";
import { useDevContextPersist } from "../../hooks/useDevContextPersist";
import { notifyUtil } from "../../utils/toast.util";
import { ENotificationTypes } from "../../types/global.interface";
import { Loader } from "../../components/Loader/Loader";

const PaymentMethodsContext = createContext<any>(null);

const storeReducer: Reducer<IPaymentMethodsData, IPaymentMethodsAction> = (state: IPaymentMethodsData, action: IPaymentMethodsAction) => {
  switch (action.type) {

    case EPaymentMethodsActionTypes.REGISTER_CARD_DATA:
      return { ...state, registerCardData: action.payload }

    case EPaymentMethodsActionTypes.SET_STATE_BY_SESSION_BACKUP:
      console.log("SET_STATE_BY_SESSION_BACKUP", action.payload)
      return { ...action.payload }

    default:
      return state;
  }
}

const initialState = {
  registerCardData: null,
}

const PaymentMethodsProvider = ({ children }: IPaymentMethodsProviderProps) => {
  const theme = useTheme() as any;
  const [ paymentMethods, setPaymentMethods ] = useReducer<Reducer<IPaymentMethodsData, IPaymentMethodsAction>>(storeReducer, initialState);
  const [ isReady, setIsReady ] = useState(false);
  useDevContextPersist(paymentMethods, "MobbyAppReactPaymentoMethods");

  //SETUP DO CONTEXT DO MÓDULO
  const setupContext = () => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        getStateFromStorageUtil("MobbyAppReactPaymentoMethods", setPaymentMethods);

        resolve()

      } catch (err) {
        reject(err)
      }
    })
  }

  useEffect(() => {
    setupContext()
      .then(res => {
        setIsReady(true);
      })
      .catch(err => {
        setIsReady(true);
        notifyUtil(ENotificationTypes.Error, `[PaymentMethods] Erro ao carregar o contexto do módulo.`)
      })
  }, [])

  if (!isReady) return (<Loader color={ theme.companySecondary } isFullSize text={ "Carregando..." }/>)


  return (
    <PaymentMethodsContext.Provider value={ { paymentMethods, setPaymentMethods } }>
      { children }
    </PaymentMethodsContext.Provider>
  )
}

export {
  PaymentMethodsContext,
  PaymentMethodsProvider
}