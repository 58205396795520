import React from "react";
import { emitEventUtil } from "./event.util";

const getStorageItem = (key: string): string => sessionStorage.getItem(key) || "";

const setStorageItem = (key: string, value: any) => sessionStorage.setItem(key, JSON.stringify(value));

const removeStorageItem = (key: string) => sessionStorage.removeItem(key);

const saveStateOnStorage = (moduleName: string, state: any) => {
  const key = `${ moduleName }State`;

  if (Object.keys(state).length === 0) return;

  sessionStorage.setItem(key, JSON.stringify(state))
}

const getStateFromStorage = (moduleName: string, setState: React.Dispatch<any>) => {
  const key = `${ moduleName }State`;
  const state = getStorageItem(key) || null;
  const urlPath = window.location.pathname.replace(/\//g, '');

  if (!state) return;

  if (urlPath === `${ moduleName }`) {
    return removeStorageItem(key);
  }

  setState({
    type: "SET_STATE_BY_SESSION_BACKUP",
    payload: JSON.parse(state)
  });
}

const clearStatesAndStorage = () => {
  sessionStorage.clear();
  removeStorageItem("error");
  emitEventUtil("@greenpass/mobby-shared-store/clear-state", null)
}

export {
  getStorageItem as getStorageItemUtil,
  setStorageItem as setStorageItemUtil,
  removeStorageItem as removeStorageItemUtil,
  saveStateOnStorage as saveStateOnStorageUtil,
  getStateFromStorage as getStateFromStorageUtil,
  clearStatesAndStorage as clearStatesAndStorageUtil
}